<template>
  <div class="flex justify-center items-center flex-col h-screen w-screen bg-gray-200 relative">
    <div class="legend absolute left-0 bottom-0 px-8 pt-14 pb-6 mb-4 flex flex-col justify-center items-start">
      <div class="legend-entry text-gray-500 hover:text-gray-300 mb-8 relative flex pl-5">
        <span class="bg-yellow-500 absolute top-1 left-0 w-4 h-4 rounded-full"></span>
        Aktuelle Woche / Vorbestellungen eingeschränkt möglich
      </div>
      <div class="legend-entry text-gray-500 hover:text-gray-300 mb-8 relative flex pl-5">
        <span class="bg-green-500 absolute top-1 left-0 w-4 h-4 rounded-full"></span>
        Nächste Woche / Vorbestellungen offen
      </div>
      <div class="legend-entry text-gray-500 hover:text-gray-300 mb-8 relative flex pl-5">
        <span class="bg-gray-500 absolute top-1 left-0 w-4 h-4 rounded-full"></span>
        Speisekarte unbekannt
      </div>
    </div>
    <nav class="bg-white shadow-md rounded px-8 pt-14 pb-6 mb-4 flex flex-col justify-center items-center">
      <a
          v-for="(week, index) in nextFourWeeks"
          :key="index"
          class="text-gray-500 hover:text-gray-300 mb-8 relative flex pr-5"
          :href="`/week?week=${ getWeekNumber(new Date(week)) }&year=${new Date(week).getFullYear()}`"
      >
        Kalenderwoche {{ getWeekNumber(new Date(week)) }} ({{ week }} - {{ getEndOfWeek(new Date(week)) }})
        <span :class="getDotColor(index)" class="absolute top-1 right-0 w-4 h-4 rounded-full"></span>
      </a>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WeekOverview',
  data() {
    return {
      imageExists: []
    }
  },
  computed: {
    nextFourWeeks() {
      const weeks = [];
      for (let i = 0; i < 2; i++) {
        const weekStart = new Date();
        weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1 + i * 7);
        weeks.push(weekStart.toISOString().split('T')[0]);
      }
      return weeks;
    },
  },
  methods: {
    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      return weekNo;
    },
    getCurrentWeekNumber() {
      const now = new Date();
      return this.getWeekNumber(now);
    },
    getEndOfWeek(d) {
      const endOfWeek = new Date(d);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      return endOfWeek.toISOString().split('T')[0];
    },
    checkImageExists(week, year) {
      axios.get(`https://essensplan.mymefa.com/api/check-image-exists.php?week=${week}&year=${year}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      })
          .then(response => {
            this.imageExists.push(response.data.exists);
          });
    },
    getDotColor(index) {
      const weekNumber = this.getWeekNumber(new Date(this.nextFourWeeks[index]));
      if (weekNumber === this.getCurrentWeekNumber()) {
        return 'bg-yellow-500';
      }
      return this.imageExists[index] ? 'bg-green-500' : 'bg-gray-500';
    }
  },
  created() {
    this.nextFourWeeks.forEach(week => {
      let d = new Date(week);
      this.checkImageExists(this.getWeekNumber(d), d.getFullYear());
    });
  }
}
</script>

<style scoped>

</style>