<template>
  <div class="flex flex-col items-center justify-center min-h-screen container">
    <table class="table-auto bg-white rounded-lg overflow-hidden shadow">
      <thead class="bg-gray-50">
      <tr>
        <th class="px-4 py-2">Name</th>
        <th class="px-4 py-2">Monday</th>
        <th class="px-4 py-2">Tuesday</th>
        <th class="px-4 py-2">Wednesday</th>
        <th class="px-4 py-2">Thursday</th>
        <th class="px-4 py-2">Friday</th>
      </tr>
      </thead>
      <tbody>
      <StaffTableRow v-for="(name, index) in names" :key="index" :name="name" :staffData="staffData" :componentKey="componentKey" />
      </tbody>
    </table>
    <button class="mt-4 bg-black hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="submitData">Speichern</button>
    <div ref="errors" class="errors text-red-500"></div>
    <div ref="success" class="success text-green-500"></div>
  </div>
</template>

<script>
import axios from 'axios';
import staff from '@/data/staff.json';
import { useRouter } from 'vue-router';
import StaffTableRow from "@/components/StaffTableRow.vue";

export default {
  data() {
    return {
      names: staff,
      staffData: this.initializeStaffData(staff),
      week: null,
      year: null,
      componentKey: 0
    }
  },
  components: {
    StaffTableRow
  },
  methods: {
    initializeStaffData(staff) {
      const data = {};
      staff.forEach(name => {
        data[name] = {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null
        };
      });
      return data;
    },
    async fetchStaffData(week, year) {
      try {
        const response = await axios.get(`https://essensplan.mymefa.com/api/data/schedule_${week}_${year}.json`, {
          headers: {
            'Content-Type': 'application/json',
            'Expires': 'Sat, 26 Jul 1997 05:00:00 GMT',
            'Cache-control': 'no-cache'
      }

        });
        for (const [key, value] of Object.entries(response.data)) {
          this.staffData[key] = JSON.parse(value);
        }
        //this.staffData = JSON.parse(response.data);
        this.componentKey += 1;  // Force re-render
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No data found for this week.');
        } else {
          console.error(error);
        }
      }
    },
    submitData() {
      const formData = new FormData();
      formData.append('week', this.week);
      formData.append('year', this.year);
      let totalCount = {
        monday: {1: 0, 2: 0, 3: 0},
        tuesday: {1: 0, 2: 0, 3: 0},
        wednesday: {1: 0, 2: 0, 3: 0},
        thursday: {1: 0, 2: 0, 3: 0},
        friday: {1: 0, 2: 0, 3: 0},
      }

      for (const name in this.staffData) {
        for (const day in this.staffData[name]) {
          const value = this.staffData[name][day];
          if (name !== 'totalCount' && value !== null && value !== 1 && value !== 2 && value !== 3 && value !== '') {
            this.$refs.success.innerText = '';
            this.$refs.errors.innerText = `Invalid value for ${name} on ${day}: ${value}`;
            return;
          }

          if(value === 1) {
            totalCount[day][1] += 1;
          }
          if(value === 2) {
            totalCount[day][2] += 1;
          }

          if(value === 3) {
            totalCount[day][3] += 1;
          }
        }

        formData.append(name, JSON.stringify(this.staffData[name]));
      }

      formData.append('totalCount', JSON.stringify(totalCount));

      axios.post('https://essensplan.mymefa.com/api/submit.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(() => {
            this.$refs.errors.innerText = '';
            this.$refs.success.innerText = 'Data successfully submitted.';
          })
          .catch(error => {
            this.$refs.errors.innerText = error;
            this.$refs.success.innerText = '';
          });
    }
  },
  created() {
    const router = useRouter();
    this.week = router.currentRoute.value.query.week;
    this.year = router.currentRoute.value.query.year;

    if (this.week && this.year) {
      this.fetchStaffData(this.week, this.year);
    }
  }
}
</script>

<style scoped>
.table-auto {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
</style>